body {
    font: 14px sans-serif;
    background-color: white;
    margin: 0;
}

div.content {
    margin: 1em;
}

table.content {
    border-collapse: collapse;
    width: 100%;
}

tr:nth-child(odd) {
    background-color: whitesmoke;
}

tr:nth-child(even) {
    background-color: rgb(226, 231, 240);
}

th {
    background-color: rgb(67, 96, 148);
    text-align: left;
    padding: 0.8em 0.5em;
    color: white;
}

td {
    text-align: left;
    padding: 0.5em 0.5em;
}

button {
    border-radius: 12px;
    border-width: 0px;
    margin: 0;
    background-color: inherit;
    padding: 0.2em 0.5em;
    text-decoration: underline;
    color: black;
}

button:hover {
    background-color: rgb(67, 96, 148);
    color: white;
    cursor: pointer;
}

button:active {
    background-color: rgb(118, 141, 184);
    color: white;
}

button.light {
    color: white;
}

button.light:hover {
    background-color: rgb(101, 129, 180);
}

button.prominent {
    border-radius: 3px;
    border-width: 0px;
    margin: 0.5em;
    background-color: rgb(67, 96, 148);
    text-decoration: none;
    padding: 0.4em 0.8em;
    color: white;
}

button.prominent:hover {
    background-color: rgb(112, 137, 184);
    color: white;
    cursor: pointer;
}

button.prominent:active {
    background-color: rgb(87, 118, 175);
    color: white;
}

button.red {
    background-color: rgb(204, 60, 60);
}

button.red:hover {
    background-color: rgb(223, 89, 89)
}

button.red:active {
    background-color: rgb(170, 84, 84)
}

input, select {
    border-radius: 3px;
    border: 1px solid rgb(67, 96, 148);
    margin: 0;
    background-color: inherit;
    padding: 0.2em 0.5em;
    color: black;
}

input:disabled {
    border-width: 0;
}

input.error {
    border-color: rgb(219, 115, 115);
    background-color: rgb(221, 183, 183);
}

div.modal-buttons {
    margin-top: 1em;
    float: right;
}

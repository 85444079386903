div.navbar {
    background-color: rgb(67, 96, 148);
    padding: 0em 1em;
    color: white;
}

div.navbar-options {
    display: inline-block;
}

div.navbar-buttons {
    /* display: inline-block; */
    float: right;
    color: white;
    padding-top: 1em;
}

a {
    font-size: large;

    display: inline-block;
    margin: 0.5em;
    padding: 0.4em 0.6em;

    text-decoration: none;
    color: white;
    border-radius: 12px;
    background-color:rgb(67, 96, 148);
}

a:hover {
    background-color:rgb(101, 129, 180)
}

span.navbar-title {
    font-size: large;

    display: inline-block;
    margin: 0;
    margin-right: 2em;
    padding: 0.5em 0.2em;

    font-weight: bolder;
}

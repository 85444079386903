div.login {
    background-color: rgb(226, 231, 240);
    padding: 1em;
    margin: auto;
    max-width: 80%;
    text-align: center;
    border-radius: 12px;
}

table.login {
    margin: auto;
}

table.login > tbody > tr {
    background-color: inherit;
}

table.login > tbody > tr > td:nth-child(1) {
    text-align: right;
}

table.login > tbody > tr > td:nth-child(2) {
    text-align: center;
}
